
.calender-search-home{
    background: #fff;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition:  0.3s;
  }
  
  .rdrMonth{
    width: 100% !;
  }
 


  .border-calender{
    border: solid 1px orange;
  }
  
  .hover-punter{
    cursor: pointer;
    padding:8px;
    transition:  0.3s;
    cursor: pointer ;
   z-index: 10;
  }
  
  .hover-punter:hover{
    background: rgb(245, 242, 242);
    border-radius: 5px;
    cursor: pointer ;
    border-radius: 10px;
    z-index: 10;
  }
  
  
  .Button-Search{
    border-radius: 5px;
    cursor: pointer;
    border-radius: 10px;
    justify-content: center;
 
    
  }
  
  .calender-search-home {
    background: #fff;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition:  0.3s;
  }

   
  .calender-search-Acoomodation {
    background: #fff;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition:  0.3s;
  }
  
  .rdrMonth{
    width: 100% !;
  }
  

  
  .calender-search-home{
    font-size: 16px !important;
    box-shadow: 17px 20px 40px rgba(0, 0, 0, .21) !important;
    border-radius: 1.25rem;
    -webkit-user-select: none;
    user-select: none;
    width: 778px;
    position: absolute;
    right: 186px;;
    left: 0;
    margin: auto;
    top:715px;
    transition: 0.3s;
    z-index: 1000;;
  }

  .calender-search-Acoomodation {
    background: #fff;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition:  0.3s;
  }
  
  .rdrMonth{
    width: 100% !;
  }
  

  .calender-search-home{
    font-size: 16px !important;
    box-shadow: 17px 20px 40px rgba(0, 0, 0, .21) !important;
    border-radius: 1.25rem;
    -webkit-user-select: none;
    user-select: none;
    width: 778px;
    position: absolute;
    right: 186px;;
    left: 0;
    margin: auto;
    top: 715px;
    transition: 0.3s;
    z-index: 1000;
  }

  /* Para pantallas medianas (tablets) */
@media (max-width: 1020px) {
  .calender-search-home {
    width: 98%;
    top: 100px;
    left: 0px;
    right: 0px;
  }
}

/* Para pantallas pequeñas (móviles) */
@media (max-width: 640px) {
  .calender-search-home {
    width: 98%;
    top: 100px;
    left: 0px;
    right: 0px;
    box-shadow: 10px 15px 30px rgba(0, 0, 0, .21) !important; /* Ajusta la sombra */
  }
} 
  
  .border-header{
    border-bottom: 1px solid rgb(224, 224, 224);  
  }
  
  .rdrStartEdge{
    background-color: black;
  }

  .rdrEndEdge{
    background-color: black;
  }

  .text-s{
    font-family: 'Inter', Helvetica, Arial, Lucida, sans-serif;
  }
  .let-letra{
    color: #000;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
}